:root {
  /* --txt-yw: rgb(252, 230, 35, 1); */
  /* --titles-red: rgb(228, 34, 19); */

  --titles-red: rgb(239, 34, 18);
  --white: #fff;
  --content-red: rgb(255, 116, 107, 0.75);
  --content: rgb(216, 131, 125);
  --off-white: rgb(252, 252, 252, 0.75);
  /* --bg: rgb(10, 25, 47); */
  --bg: rgb(38, 35, 35);
  /* --overlay: rgb(23, 42, 69); */
  --navbar: rgb(52, 52, 52);
  --lightgray-ui: rgb(159, 155, 155);
  --overlay: rgba(80, 74, 74, 1);
  --white-gray: #d9d6d6;
}
p {
  color: var(--off-white);
  width: 65%;
  min-width: 350px;
  text-indent: 16px;
}
h1 {
  color: var(--titles-red);
}
h2 {
}
h3 {
  color: var(--off-white);
}
a {
  text-decoration: none;
  color: inherit;
  position: relative;
}
body {
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
    "Lucida Console", Monaco, sans-serif;
  font-size: 13.5px;
  /* color: var(--content-red); */
}
.about,
.landing,
.work,
.contact {
  width: 100%;
  min-height: 90vh;
  height: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px auto;
  background-color: var(--bg);
  padding-top: 100px;
}
.landing {
  padding: 0;
}
.project-container {
  min-width: 800px;
  align-items: center;
}
.project-item:nth-child(even) .project-info ul li {
  margin: 0 0 0 16px;
}
.project-stack {
  padding: 0;
  align-items: center;
  display: flex;
  height: fit-content;
  line-height: 14px;
  flex-wrap: wrap;
  margin: 9px 0;
  color: var(--off-white);
}

ul {
  display: flex;
}
li {
  list-style: none;
  text-decoration: none;
}
.stack-item {
  font-size: 11px;
  margin-right: 16px;
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
}
.app {
  width: 100vw;
  display: flex;
  background-color: rgb(221, 217, 217);
  align-items: center;
}
.app-side-liners {
  max-width: 175px;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  position: relative;
}
.app-content {
  display: flex;
  padding: 0;
  position: relative;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.75);
}
.sider-container {
  position: fixed;
  left: 107px;
  bottom: 33%;
  height: 160px;
  border-radius: 3px;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.line {
  border-bottom: 1px solid white;
  margin-top: 4px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  height: 75%;
  width: 100%;
  box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -10px;
  padding: 0 100px;
  background-color: var(--navbar);
  align-items: center;
}
.main {
  margin: 0px auto;
  width: 70%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main-content {
  width: 75%;
}
.resume.navbar-btn:hover {
  background-color: rgb(184, 62, 53, 15%);
}
.resume.navbar-btn {
  border: 1px solid var(--titles-red);
  padding: 6px 12px;
  border-radius: 2px;
  width: fit-content;
  height: 100%;
}
.resume.active-link {
  color: var(--titles-red);
  font-weight: 400;
}
.nav-wrap {
  display: flex;
  justify-content: space-between;
  height: 76px;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
}
.navbar-btn {
  padding: 13px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-btn:hover {
}
.horz-wrap {
  display: flex;
}
.active-link {
  text-decoration: none;
  color: var(--white);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.04em;
  line-height: 19px;
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
  transition: color 0.15s;
  transition-timing-function: ease-in-out;
}
.active-link:hover {
  color: var(--titles-red);
}
.row {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}
.col-1 {
  display: flex;
  padding: 0;
  position: relative;
  flex-direction: column;
  width: 100%;
}
.name {
  font-size: 68px;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
  margin-left: 12.5%;
  color: var(--white);
  width: fit-content;
}
.intro {
}
.content-cont {
  margin-top: 24px;
}
.about-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}
.connect-btn {
  margin: 60px 0;
}
.profile-cont {
  height: fit-content;
  width: fit-content;
  position: relative;
}
.profile-img {
  background-image: url("./images/profile-img.jpeg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  height: 300px;
  width: 280px;
}
.slogan-cont {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}
.summary-cont {
  width: 65%;
}
.summary-text {
  margin: 0;
  padding-bottom: 8px;
  color: #fff;
  font-size: 0.95em;
}
.project-item {
  position: relative;
  min-height: 400px;
  padding: 0;
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.project-item:nth-child(even) {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 30%;
}
.project-item:nth-child(odd) {
  margin-left: 30%;
}
.project-item:nth-child(even) .project-info .prj-title {
  justify-content: flex-end;
}
.project-info {
  height: fit-content;
  width: fit-content;
  position: relative;
}
.work-content {
  max-width: 800px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
}
.tech-skills-cont {
  min-width: 400px;
  width: 50%;
  /* padding: 75px 0; */
}
.skill-item {
  height: 24px;
  width: 175px;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-family: "Ubuntu";
}
.bullet-pt {
  color: var(--content-red);
  font-size: 16px;
  transform: rotate(270deg);
  height: 20px;
  width: 20px;
  position: absolute;
  left: 0;
  bottom: -3px;
}
.bullet-wrap {
  display: flex;
  position: relative;
  width: fit-content;
  transform: rotateX(180deg);
}
.skill-text {
  color: var(--off-white);
  margin-left: 20px;
}
.prj-title {
  font-size: 28px;
  text-decoration: none;
  font-family: "Ubuntu", sans-serif;
  color: var(--white);
  font-weight: 600;
  padding-bottom: 12px;
  border-radius: 2px;
  justify-content: space-between;
}

.prj-link {
  font-size: 14px;
}

.prj-title.row a i:hover {
  font-weight: 900;
}

.prj-desc {
  line-height: 18px;
  font-size: 0.85em;
  font-family: "Hind Madurai", sans-serif;
  font-size: 13.5px;
  color: var(--off-white);
}
.contact-item {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 8px 0;
  justify-content: center;
}
/* .contact-item:hover {
  background-color: var(--content-red);
  margin-left: 8px;
} */
.contact-links {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  margin-left: 16px;
}
.icon {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}
.icon span {
  position: relative;
}
/* .icon:hover {
  background-color: var(--content-red);
} */

.section-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 28px;
  font-family: "Ubuntu", sans-serif;
  width: 95%;
  margin: 8px 0 12px 12px;
  color: var(--white);
}
.section-title-label {
  font-size: 20px;
  font-weight: 100;
  line-height: 36px;
  color: var(--titles-red);
}
.section-horz-line {
  width: 100%;
  margin-top: 12px;
  height: 1px;
  background-color: var(--content-red);
}
.section-header {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 72px;
  margin-top: 16px;
}
.project-section {
  height: fit-content;
}
.img-wrap {
  height: fit-content;
  width: fit-content;
  position: relative;
}
.name-cont {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
}
.dampener {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--content-red);
  border-radius: 3px;
  opacity: 0.35;
  max-width: 100%;
  max-height: 98.5%;
  min-height: 295px;
  min-width: 495px;
  transition: background-color 0.25s;
}
.dampener:hover {
  background-color: transparent;
}
.profile-header-cont {
  margin-top: 52px;
  display: flex;
  width: 80%;
  margin-bottom: 16px;
  align-items: flex-end;
}
.profile-wrap {
  display: flex;
  width: fit-content;
  width: 100%;
  margin-top: 16px;
}
.skill-list {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0 0 0;
  padding: 0;
}
.fa.fa-envelope {
  font-size: 23.75px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.overlay-container {
  padding: 40px 25px 25px 25px;
  background-color: var(--navbar);
  z-index: 1;
  border-radius: 3px;
  min-width: 100%;
  position: relative;
  box-shadow: 0 8px 16px -4px rgba(9, 30, 66, 0.25),
    0 0 0 1px rgba(9, 30, 66, 0.08);
}
.project-refs {
  right: 12px;
  position: absolute;
  top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill-icon {
  background-size: contain;
  height: 50px;
  width: 75px;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid white;
  border-radius: 3px;
  margin: 4px 8px;
  font-weight: 400;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 8px 16px -4px rgba(9, 30, 66, 0.25),
    0 0 0 1px rgba(9, 30, 66, 0.08);
  box-sizing: border-box;
  outline: 0;
  overflow: hidden;
}
.project-img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 500px;
  height: 300px;
  border: 2px black;
  box-shadow: 0 8px 16px -4px rgba(9, 30, 66, 0.25),
    0 0 0 1px rgba(9, 30, 66, 0.08);
  border-radius: 3px;
}
.about-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.projects-cont {
  width: 97%;
  display: flex;
  flex-direction: column;
}

.email-sider {
  right: 0;
  position: fixed;
  top: 50%;
  bottom: 0;
  width: -moz-fit-content;
  width: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
}
.email-wrap {
  height: 175px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fixed-email {
  transform: rotate(90deg);
  font-size: 14px;
  color: #fff;
}
.fa {
  font-size: 28px;
  color: var(--navbar);
}
.fa:hover {
  /* color: var(--lightgray-ui); */
  color: rgb(91, 85, 85);
  /* opacity: 0.6; */
  /* background-size: contain;
  background-color: var(--lightgray-ui);
  border-radius: 3px; */
}
.p-i {
  color: var(--lightgray-ui);
}
.p-i:hover {
  /* color: var(--white); */
  color: whitesmoke;
}

.active-project-cont {
  width: fit-content;
  position: absolute;
  right: 16px;
  top: -20px;
}
.open-link-icon {
  height: 16px;
  width: 16px;
  position: absolute;
  right: 8px;
  top: 8px;
}
.nav-num {
  font-size: 14px;
  letter-spacing: 0.0475em;
  color: var(--titles-red);
  margin: 0 3px 4px 0;
}
.clipboard {
  height: 30px;
  width: fit-content;
  border-radius: 3px;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
  position: relative;
}
.clip-cont {
  background-color: var(--white);
  border-radius: 2px;
  box-shadow: 0 8px 16px -4px rgba(9, 30, 66, 0.25),
    0 0 0 1px rgba(118, 126, 139, 0.08);
  padding: 2px 4px;
}
.clip-title {
  height: 28px;
  width: 90%;
  font-size: 13px;
  font-weight: 600;
  color: black;
  justify-content: center;
  align-items: flex-start;
  margin: 6px 0 0 8px;
  cursor: default;
  letter-spacing: 0;
  line-height: 19px;
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
  border-bottom: 0.75px solid var(--lightgray-ui);
}
.clip-text {
  border-radius: 2px;
  border: none;
  padding: 0;
  margin: 2px 4px 0;
  height: 22px;
  width: 160px;
  resize: none;
  font-family: "Ubuntu", sans-serif;
  text-align: justify;
  -moz-text-align-last: center; /* Firefox 12+ */
  text-align-last: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
}
textarea.clip-text {
  background-color: #d9d6d6;
}
.fa.fa-clipboard {
  height: 22px;
  width: 22px;
  font-size: 20px;
  padding: 3px;
  position: relative;
  border-radius: 3px;
  top: 0;
  right: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--lightgray-ui);
}
.fa.fa-clipboard:hover {
  color: var(--titles-red);
}
.popup-info {
  padding: 3px 8px;
  font-size: 10.5px;
  position: absolute;
  border-radius: 2px;
  width: -moz-fit-content;
  width: fit-content;
  color: black;
  background-color: var(--white-gray);
  left: -24px;
  top: 42px;
  box-shadow: 0 8px 16px -4px rgba(9, 30, 66, 0.25),
    0 0 0 1px rgba(118, 126, 139, 0.08);
}
.triangle-up {
  height: 2px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid var(--white-gray);
  position: absolute;
  top: -12px;
  left: 36px;
}
.popover {
  position: absolute;
  top: -80px;
  border-radius: 2px;
  left: 60px;
  bottom: 0;
  z-index: 2;
  width: fit-content;
  height: fit-content;
}
/*    */
.project-item.project-item:nth-child(even) div div div.project-refs {
  right: unset;
  left: 12px;
}
.btn-hover-bg,
.p-i.btn-hover-bg {
  height: 23px;
  width: 23px;
  border-radius: 5px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-hover-bg:hover {
  background-color: var(--titles-red);
}
.p-i.btn-hover-bg:hover {
  background-color: var(--content-red);
}
.close-btn {
  width: 28px;
  height: 28px;
  position: absolute;
  right: 0px;
  top: 2px;
  background: none;
  border: none;
  color: black;
}
.popup-header {
  height: 28px;
  position: relative;
  width: 100%;
  margin-bottom: 8px;
}
.close-btn span:hover {
  font-weight: 700;
  color: var(--lightgray-ui);
}
.email.navbar-btn {
  border: 1px solid var(--titles-red);
  border-radius: 2px;
  width: fit-content;
  padding: 16px 20px;
  height: 100%;
}
.email.active-link {
  text-transform: none;
  color: var(--titles-red);
  font-size: 15px;
}
.email.navbar-btn:hover {
  background-color: rgb(184, 62, 53, 15%);
}
.skill-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  font-family: "Ubuntu", sans-serif;
  color: var(--off-white);
}
